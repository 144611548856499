<template>
<div class="flex-container">
  <div class="flex-col">
      <img v-for="(image, index) in images1" 
        :key="index" :src="image" alt="Image" 
        @click="showPreview(image)"
        class="flex-item">
  </div>
  <div class="flex-col">
    <img v-for="(image, index) in images2" 
      :key="index" :src="image" alt="Image" 
      @click="showPreview(image)"
      class="flex-item">
  </div>
  <div class="flex-col">
    <img v-for="(image, index) in images3" 
      :key="index" :src="image" alt="Image" 
      @click="showPreview(image)"
      class="flex-item">
  </div>
  <div class="flex-col">
    <img v-for="(image, index) in images4" 
      :key="index" :src="image" alt="Image" 
      @click="showPreview(image)"
      class="flex-item">
  </div>

  <div class="modal" v-if="previewImage" @click="closePreview">
      <img :src="previewImage" alt="Preview">
  </div>
</div>
</template>

<script>
export default {
  name: 'ArtPortfolio',
  data() {
      return {
          images1: [
          //alicent
          'https://64.media.tumblr.com/ec1e9f9d4fd7ef15473574e6a1c51009/18aa44ca891ed95f-fd/s1280x1920/9ded9b1d79948b84a4c320c60ecc2aff17dbf290.png',
          //silverkey
          'https://64.media.tumblr.com/18682abe33e53ccf48ab520c64bb87bc/f4d735080760fc87-91/s1280x1920/b912e134f07aefa0ab332d27e4693d4f62a12d6d.jpg',
          //dhari
          'https://64.media.tumblr.com/f3f439e272a2c3040eef5ca3ca21aa0f/e7025abd376e03fa-25/s1280x1920/e46131e44f815aeb17f868c580fb01e83224584d.png',          
          //scifi gal
          'https://64.media.tumblr.com/88fefa08d7e40c13ca6ed6eefdd6150f/9a2c53dc4f93d63f-18/s1280x1920/9355c11f13faf49a9e936f072890d706a7e9d97c.png',
          //ashev
          'https://64.media.tumblr.com/a769e28f7325ca5c88abd12cd5df5300/948667200879cc94-02/s2048x3072/94c72338936022071c982a2bac3878ce9b2d30f9.png'
          ],
          images2: [
          //janblue
          'https://64.media.tumblr.com/9c13d1b27ecb6de4550893f774ec0745/5176d86ea094ca23-88/s1280x1920/724f5c04e01ea29b96d48642c9b065565703bfc2.png',
          //janintro
          'https://64.media.tumblr.com/12a4383912e0c984188a2fd8f876a787/309696a6b2c8ccc8-9f/s1280x1920/d746bab5a59d860b4758543c5a3028f20219b082.png',
          //canary
          'https://64.media.tumblr.com/d03cee59eae42f5a3c461e40d9ca7b92/d5159c9ffe5ceebd-ec/s2048x3072/a58f63d9b66d3c33f6d9429c69591a7079725fe4.png', 
          //elemental
          'https://64.media.tumblr.com/c0f529e10be4e03c39dafdf8c61c49ad/f7922107ee7fa608-16/s2048x3072/7cdd21b458dc62cc0c0f413b2715fc70045a3a97.jpg',
          //bbyods
          'https://64.media.tumblr.com/e891f1441a3fa83fccad1a658ec6020d/87b06c940dea3ea4-10/s2048x3072/daa1b3e87a4677ae1e86aeb157a25898a68a311c.jpg'             
          ],
          images3: [
          //janorange
          'https://64.media.tumblr.com/4dd0939f2fffa76adbad4f5cf2595ee0/5176d86ea094ca23-3e/s1280x1920/64106374113f0b4a75b91809bc24db6a35bff4ef.png',
          //window
          'https://64.media.tumblr.com/03b9856a2895d63a04ac61cae1ebe826/ee609e66791d5efc-56/s2048x3072/110ac400b73adbba72dbe73bf203b3151644ed26.png',
          //spookyceryn
          'https://64.media.tumblr.com/e7b6cb638d04f89cc3344936afc974f5/ee8d95282ca8adcc-77/s1280x1920/eb321d14dfe7d64d647eefb5f2759b35235ef980.png',
          //sera
          'https://64.media.tumblr.com/4a01d7ce6ce561fa544daa3b94a11ea6/83da217c287eaaac-44/s2048x3072/a2b1b0d3d1f9dc42b935cbc2494cc2ff2184ba69.png'
          ],
          images4: [
          //anna
          'https://64.media.tumblr.com/c3a50d8d9dd87fa7ca62a6da5661a3ec/9967f9d2d80ce9c7-a0/s1280x1920/f9e086cb27864c3cdf68a1979880927780e9c816.png',            
          //sakura
          'https://64.media.tumblr.com/cb2ecd3a704ae06b06bdfaca041ac555/f4d735080760fc87-e2/s1280x1920/a08db029ea993d97607a7974404b997838453adf.jpg',
          //merl
          'https://64.media.tumblr.com/c81b000d3b4881e07d78bf329aa91617/e2e5de0cf59a5993-bd/s2048x3072/b017e15619d479f779f61f61a5a367115bebe5e5.png',
          //girl
          'https://64.media.tumblr.com/be1bd77ee18d4fdc05dc897afd8ffd04/830fb89d3a8556a1-aa/s2048x3072/af17a82a0d1adcb56427317c3251a01bb63b642b.png',
          //50s lass
          'https://64.media.tumblr.com/23ed3c87862b46ff63e95e551e3739c8/1ca18379d2d4c158-25/s2048x3072/721b7ecd35c626b16c25c37161902e9a8b572bfd.png'
          ],
          previewImage: ''
      };
  },
  methods: {
      showPreview(image) {
          this.previewImage = image;
      },
      closePreview() {
          this.previewImage = '';
      }
  }
}
</script>


  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .flex-container {
    background-color: var(--inkblue);
    padding: 20px 30px;
    border-style: solid;
    border-color: var(--oldyellow);

    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-around;
    height: 100%;
    gap: 15px;
  }
  .flex-col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: space-around;
    gap: 15px;
  }
  .flex-item{
    width: 200px;
    outline-style: solid;
    outline-width: 2px;
    outline-color: var(--rosepink);
  }

  @media screen and (max-width: 850px) {
    .flex-container {
      flex-direction: column;
      padding: 15px 15px;
      border-width: 2px;
    }
    .flex-item{
      width: 300px;
    }
  }

  .modal {
        display: block;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .modal img {
        margin: auto;
        margin-top: 40px;
        display: block;
        max-width: 80%;
        max-height: 80%;
    }

    .close {
        color: #fff;
        position: absolute;
        top: 15px;
        right: 35px;
        font-size: 30px;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: #ccc;
        text-decoration: none;
        cursor: pointer;
    }
  </style>
